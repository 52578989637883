import Typography from "@mui/material/Typography"
import dynamic from "next/dynamic"
import { useSelector } from "react-redux"
import { count, isDefined, vatPricing } from "../../config/helpers"
// import ProductPriceTypography from "../Product/ProductPriceTypography"

const ProductPriceTypography = dynamic(() => import("../ProductComponent/ProductPriceTypography"), {
    ssr: true,
});


const ProductPriceContainer = (props) => {
    const {
        on_offer, poa_price, price_breaks, product_price, new_price
    } = props;
    const { VATFlag, tax } = useSelector((state) => state.globalReducer);
    return (
        <Typography className="mb-2">
            {on_offer == 'yes' ?
                poa_price == 'no' ?
                    price_breaks && count(price_breaks) > 0 ? (
                        <>
                            <ProductPriceTypography on_offer={on_offer} type="has_offer" price={vatPricing(VATFlag, tax, new_price)} />
                        </>
                    )
                    : (
                        <>
                            <ProductPriceTypography on_offer={on_offer} type="no_offer" price={vatPricing(VATFlag, tax, new_price)} />
                        </>
                    )
                : (
                    <span>POA</span>
                )
            : (
                poa_price == 'no' ? (
                    isDefined(price_breaks) && count(price_breaks) > 0 ? (
                        <>
                            <ProductPriceTypography type="has_offer" price={vatPricing(VATFlag, tax, product_price)} />
                        </>
                    ) : (
                        <>
                            <ProductPriceTypography type="no_offer" price={vatPricing(VATFlag, tax, product_price)} />
                        </>
                    )
                ) : (
                    <span>POA</span>
                )
            )}
        {/* Long term hire section */}
        </Typography>
    )
}

export default ProductPriceContainer